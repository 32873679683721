import * as _assets from "./assets";
import * as global from "components/Global";
import * as styled from "./styles";

export const Investors = () => {
  return (
    <styled.InvestorsWrapper>
      <img src={_assets.INVESTORS_GROUP} alt="investors icon" />
      <h3>Investors</h3>
      <h2>Get in on the future.</h2>
      <p>
        We're building a revolutionary web3 e-commerce marketplace with
        innovative features and multiple monetization pathways.
      </p>
      <a
        href="https://docsend.com/view/8gayg6fwntjyd6fj"
        target="_blank"
        rel="noreferrer"
      >
        <global.PrimaryButtonBig>Whitepaper</global.PrimaryButtonBig>
      </a>
      <a href="https://ferrislabs.io/" target="_blank" rel="noreferrer">
        About Ferris Labs
      </a>
    </styled.InvestorsWrapper>
  );
};
