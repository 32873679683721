import styled from "@emotion/styled";
import * as _assets from "./assets";
import * as consts from "consts";

export const SignUpWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  justifyItems: "center",
  padding: "80px 13px",

  position: "relative",
  width: "100%",
  maxWidth: "1190px",
  margin: "0 auto",
  backgroundImage: `url(${_assets.SIGNUP_BG})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",

  border: "1px solid #666",
  borderRadius: "500px",

  [consts.mobileQuery]: {
    margin: "0 30px",
    width: "calc(100% - 60px)",
  },

  h3: {
    color: "#FFF",
    fontSize: "20px",
    fontWeight: 900,
    lineHeight: "21px",
    letterSpacing: "-0.4px",

    [consts.mobileQuery]: {
      fontSize: "14px",
      lineHeight: "21px",
      letterSpacing: "-0.28px",
    },
  },
  h2: {
    color: "#FFF",
    fontSize: "50px",
    fontWeight: 700,
    letterSpacing: "-0.32px",

    [consts.mobileQuery]: {
      width: "170px",
      margin: "0 auto 20px",
      fontSize: "32px",
      lineHeight: "33px",
      letterSpacing: "-0.32px",
    },
  },
  p: {
    maxWidth: "638px",
    color: "#FFF",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    textAlign: "center",
    margin: "0 auto",

    [consts.mobileQuery]: {
      maxWidth: "328px",
      fontSize: "14px",
      lineHeight: "18px",
    },
  },

  input: {
    height: "48px",
    maxWidth: "400px",
    width: "100%",
    paddingLeft: "20px",
    fontSize: "14px",
    fontWeight: 600,
    color: "#000",
    letterSpacing: "1.4px",
    margin: "24px auto",

    [consts.mobileQuery]: {
      height: "38px",
      fontSize: "12px",
    },
  },

  button: {
    margin: "0 auto",
  },
});

export const SignUpContainer = styled.div({});
