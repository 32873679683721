import { useRef } from "react";
import { Banner } from "components/Banner";
import { Footer } from "components/Footer";
import { Investors } from "components/Investors";
import { Navbar, ScrollTypes } from "components/Navbar";
import { SignUp } from "components/Signup";

export function LandingPage() {
  const signUpRef = useRef(null);
  const investorsRef = useRef(null);

  const scrollTo = (type: ScrollTypes) => {
    if (type === "Investors") {
      (signUpRef.current as any).scrollIntoView({ behavior: "smooth" });
    } else {
      (signUpRef.current as any).scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Navbar scrollTo={scrollTo} />
      <Banner />
      <div ref={signUpRef}>
        <SignUp />
      </div>
      <div ref={investorsRef}>
        <Investors />
      </div>

      <Footer />
    </>
  );
}
