import styled from "@emotion/styled";
import { motion } from "framer-motion";
import * as consts from "consts";

export const NavbarWrapper = styled.div({
  position: "fixed",
  display: "flex",

  justifyContent: "center",
  height: "85px",
  width: "100%",

  borderBottom: "1px solid #888888",
  padding: "25px 40px",

  zIndex: 999,
  backgroundColor: "#F8F8F9",

  img: {
    width: "177px",
    height: "34px",
  },

  [consts.mobileQuery]: {
    height: "55px",
    padding: "17px 14px",

    img: {
      width: "119px",
      height: "22px",
    },
  },

  ".container": {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "1440px",
  },

  ".burger-section": {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },

  ".link-section": {
    display: "flex",
    flexDirection: "row",
    gap: "48px",
  },
});

export const MobileMenu = styled(motion.div)({
  position: "fixed",
  height: "100%",
  top: "85px",
  right: 0,
  width: "250px",
  borderLeft: "1px solid #888888",
  backgroundColor: "#F8F8F9",
  zIndex: 999,

  [consts.mobileQuery]: {
    top: "55px",
  },

  ".link-section": {
    marginTop: "25px",
    display: "flex",
    flexDirection: "column",
    gap: "25px",

    button: {
      margin: "0 auto",
    },
  },
});

export const LinkLabel = styled.a({
  cursor: "pointer",
  color: "#000",
  textAlign: "center",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "26px",
});
