import * as _assets from "./assets";
import * as styled from "./styles";

export const Banner = () => {
  return (
    <styled.BannerWrapper>
      <styled.BannerContainer>
        <div className="banner-pillars">
          <img id="super-top-left" src={_assets.BAG_GROUP} alt="Bag" />
          <img id="top-left" src={_assets.USDC_GROUP} alt="USDC" />
          <img id="top-right" src={_assets.MACBOOK_GROUP} alt="Macbook" />
          <img
            id="super-top-right"
            src={_assets.OPEN_FLEA_GROUP}
            alt="Open Flea"
          />

          <img id="bottom-left" src={_assets.HAT_GROUP} alt="hat" />
          <img
            id="bottom-middle"
            src={_assets.HEADPHONE_GROUP}
            alt="headphone"
          />
          <img id="bottom-right" src={_assets.TENNIS_GROUP} alt="Tennis" />
          <img
            id="super-bottom-right"
            src={_assets.GLASSES_GROUP}
            alt="Glasses"
          />
        </div>
        <div className="banner-section">
          <h3>Coming 2024</h3>
          <h1>
            Endless shopping. <br /> Protected by blockchain.
          </h1>
          <p>
            The world of crypto meets physical goods. Secure payments, seamless
            shipping, and tailored shopping recommendations, OpenFlea is
            building the future of e-commerce.
          </p>
        </div>
      </styled.BannerContainer>
      <styled.MiddleLine></styled.MiddleLine>
    </styled.BannerWrapper>
  );
};
