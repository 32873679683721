import * as _assets from "./assets";

import * as styled from "./styles";

export const Footer = () => {
  return (
    <>
      <styled.FooterWrapper>
        <p>© OpenFlea {new Date().getFullYear()}. All rights reserved.</p>
        <a
          href="https://ferrislabs.io"
          target="_blank"
          rel="noreferrer"
          className="logo-desktop"
        >
          <img
            src={_assets.FERRIS_LOGO}
            alt="ferris logo"
            className="logo-desktop"
          />
        </a>
        <div className="socials">
          {/* <a
            href="https://instagram.com/openflea_"
            target="_blank"
            rel="noreferrer"
          >
            <_assets.InstagramIcon />
          </a> */}
          <a
            href="https://twitter.com/Ferris_Labs"
            target="_blank"
            rel="noreferrer"
          >
            <_assets.TwitterIcon />
          </a>
        </div>
      </styled.FooterWrapper>

      <styled.FooterMobileWrapper>
        <styled.FooterWrapper>
          <a href="https://ferrislabs.io" target="_blank" rel="noreferrer">
            <img
              src={_assets.FERRIS_LOGO}
              alt="ferris logo"
              className="logo-mobile"
            />
          </a>
        </styled.FooterWrapper>
      </styled.FooterMobileWrapper>
    </>
  );
};
