import styled from "@emotion/styled";

export const PrimaryButton = styled.button({
  color: "#FFFF",
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "26px",
  letterSpacing: "-0.16px",
  width: "135px",
  height: "30px",
  background: "#F36C1B",
  borderRadius: "50px",
});

export const PrimaryButtonBig = styled.button({
  color: "#FFFF",
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "26px",
  letterSpacing: "-0.16px",
  width: "200px",
  height: "44px",
  background: "#F36C1B",
  borderRadius: "200px",
});

export const SecondaryButton = styled.button({
  color: "#FFFF",
  fontSize: "16px",
  fontWeight: "600",
  width: "162px",
  height: "44px",
  border: "2px solid #FFF",
  borderRadius: "200px",
});
