import styled from "@emotion/styled";
import * as consts from "consts";

export const BannerWrapper = styled.div({
  position: "relative",
  width: "100%",
  overflow: "hidden",
  paddingTop: "85px",

  [consts.tabletQuery]: {
    paddingTop: "55px",
  },

  [consts.mobileQuery]: {
    paddingTop: "35px",
  },
});

export const MiddleLine = styled.div({
  background: "#666",
  margin: "auto",
  width: "1px",
  height: "300px",
});

export const BannerContainer = styled.div({
  position: "relative",
  margin: "120px auto 0",
  borderTop: "1px solid #666",
  borderBottom: "1px solid #666",

  height: "640px",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",

  [consts.thiccQuery]: {
    border: "1px solid #666",
    width: "90vw",
    maxWidth: "1240px",
  },

  [consts.mobileQuery]: {
    height: "455px",
    margin: "55px auto 0",
  },

  ".banner-pillars": {
    position: "absolute",
    width: "100%",
    height: "100%",

    "#top-left": {
      position: "absolute",
      top: "-25%",
      left: "18%",

      [consts.thiccQuery]: {
        top: "-25%",
        left: "-150px",
      },

      [consts.tabletQuery]: {
        height: "212px",
        top: "-17%",
        left: "-90px",
      },

      [consts.mobileQuery]: {
        height: "128px",
        top: "-10%",
        left: "-42px",
      },
    },

    "#super-top-left": {
      position: "absolute",
      top: "-25%",
      left: "-2%",

      [consts.thiccQuery]: {
        display: "none",
      },
    },
    "#top-right": {
      position: "absolute",
      top: "-25%",
      right: "18%",
      borderRadius: "307.17px",
      backgroundColor: "#f2f2f4",

      [consts.thiccQuery]: {
        top: "-25%",
        right: "-15%",
      },

      [consts.tabletQuery]: {
        height: "212px",
        top: "-17%",
        right: "-90px",
      },

      [consts.mobileQuery]: {
        height: "128px",
        top: "-10%",
        right: "-12%",
      },
    },
    "#super-top-right": {
      position: "absolute",
      top: "-25%",
      right: "-5%",

      [consts.thiccQuery]: {
        display: "none",
      },
    },
    "#bottom-left": {
      position: "absolute",

      borderRadius: "307.17px",
      backgroundColor: "#f2f2f4",

      bottom: "-25%",
      left: "14%",

      [consts.thiccQuery]: {
        bottom: "-25%",
        left: "-180px",
      },

      [consts.tabletQuery]: {
        height: "212px",
        bottom: "-17%",
        left: "-90px",
      },

      [consts.mobileQuery]: {
        height: "128px",
        bottom: "-10%",
        left: "-40px",
      },
    },
    "#bottom-middle": {
      position: "absolute",
      bottom: "-25%",
      left: "calc(50% - 157px)",

      [consts.tabletQuery]: {
        height: "212px",
        bottom: "-17%",
        left: "calc(50% - 106px)",
      },

      [consts.mobileQuery]: {
        height: "128px",
        bottom: "-55%",
        left: "calc(50% - 64px)",
      },
    },
    "#bottom-right": {
      position: "absolute",

      bottom: "-25%",
      right: "18%",

      [consts.thiccQuery]: {
        bottom: "-25%",
        right: "-195px",
      },

      [consts.tabletQuery]: {
        height: "212px",
        bottom: "-17%",
        right: "-100px",
      },

      [consts.mobileQuery]: {
        height: "128px",
        bottom: "-10%",
        right: "-45px",
      },
    },
  },
  "#super-bottom-right": {
    position: "absolute",
    bottom: "-25%",
    right: "-2%",

    [consts.thiccQuery]: {
      display: "none",
    },
  },

  ".banner-section": {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    textAlign: "center",

    p: {
      margin: "0 auto",
      paddingBottom: "100px",
      color: "#000",
      textAlign: "center",
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "28px",
      maxWidth: "640px",

      [consts.mobileQuery]: {
        paddingBottom: "10px",
        margin: "15px 30px 0",
        fontSize: "14px",
        lineHeight: "18px",
      },
    },
    h1: {
      color: "#000",
      textAlign: "center",
      fontSize: "60px",
      lineHeight: "68px",
      fontWeight: 900,
      letterSpacing: "-0.6px",
      marginBottom: "20px",

      [consts.mobileQuery]: {
        fontSize: "32px",
        lineHeight: "35px",
        fontWeight: 900,
        letterSpacing: "-0.32px",
        marginBottom: "0",
      },
    },
    h3: {
      color: "#F36C1B",
      textAlign: "center",
      fontSize: "22px",
      fontWeight: 900,
      lineHeight: "30px",

      [consts.mobileQuery]: {
        fontSize: "14px",
      },
    },
  },
});
