import { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";

import * as assets from "assets";
import * as hooks from "hooks";
import * as global from "components/Global";
import * as consts from "consts";

import * as styled from "./styles";

export type ScrollTypes = "SignUp" | "Investors";

export const Navbar = ({
  scrollTo,
}: {
  scrollTo: (type: ScrollTypes) => void;
}) => {
  const [toggleSideMenu, setToggleSideMenu] = useState<boolean>(false);

  const isDesktop = hooks.useMediaQuery(
    `(min-width: ${consts.mobileBreakpoint}px)`
  );

  useEffect(() => {
    if (isDesktop) {
      setToggleSideMenu(false);
    }
  }, [isDesktop]);

  return (
    <>
      <styled.NavbarWrapper>
        <div className="container">
          <img src={assets.OPENFLEA_LOGO} alt="openflea logo" />
          {isDesktop ? (
            <div className="link-section">
              <styled.LinkLabel
                href="https://docsend.com/view/8gayg6fwntjyd6fj"
                target="_blank"
                rel="noreferrer"
              >
                Whitepaper
              </styled.LinkLabel>
              <styled.LinkLabel onClick={() => scrollTo("SignUp")}>
                Buyers + Sellers
              </styled.LinkLabel>
              <styled.LinkLabel onClick={() => scrollTo("Investors")}>
                Investors
              </styled.LinkLabel>
              <a href="mailto:contact@openflea.com">
                <global.PrimaryButton>Contact</global.PrimaryButton>
              </a>
            </div>
          ) : (
            <div
              className="burger-section"
              onClick={() => setToggleSideMenu(!toggleSideMenu)}
            >
              <assets.BurgerIcon />
            </div>
          )}
        </div>
      </styled.NavbarWrapper>

      <AnimatePresence>
        {toggleSideMenu && (
          <styled.MobileMenu
            key={"mobile-side"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="link-section">
              <styled.LinkLabel
                href="https://docsend.com/view/8gayg6fwntjyd6fj"
                target="_blank"
                rel="noreferrer"
              >
                Whitepaper
              </styled.LinkLabel>
              <styled.LinkLabel onClick={() => scrollTo("SignUp")}>
                Buyers + Sellers
              </styled.LinkLabel>
              <styled.LinkLabel onClick={() => scrollTo("Investors")}>
                Investors
              </styled.LinkLabel>
              <global.PrimaryButton>Contact</global.PrimaryButton>
            </div>
          </styled.MobileMenu>
        )}
      </AnimatePresence>
    </>
  );
};
