import styled from "@emotion/styled";
import * as consts from "consts";

export const InvestorsWrapper = styled.div({
  width: "100%",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingBottom: "142px",

  img: {
    margin: "0 auto",
  },

  h2: {
    color: "#000",
    textAlign: "center",
    fontSize: "50px",
    fontWeight: 700,
    letterSpacing: "-0.32px",

    [consts.mobileQuery]: {
      fontSize: "32px",
      lineHeight: "36px",
      width: "180px",
      margin: "0 auto",
    },
  },

  h3: {
    marginTop: "-40px",
    color: "#000",
    fontSize: "20px",
    fontWeight: 900,
    lineHeight: "21px" /* 105% */,
    letterSpacing: "-0.4px",

    [consts.mobileQuery]: {
      fontSize: "14px",
    },
  },

  p: {
    maxWidth: "638px",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    margin: "0 auto",

    [consts.mobileQuery]: {
      fontSize: "14px",
      lineHeight: "18px",
      // width: "50px",
      margin: "10px 20px 0",
    },
  },

  button: {
    margin: "29px auto 24px",
  },

  a: {
    color: "#F36C1B",
    fontSize: "14px",
    fontWeight: 700,
  },
});
