export const BurgerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="9"
    viewBox="0 0 19 9"
    fill="none"
  >
    <path d="M0.156189 1.3009H18.5668" stroke="black" />
    <path d="M0.156189 8.12451H18.5668" stroke="black" />
  </svg>
);
