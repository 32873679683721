import { useState } from "react";
import jsonp from "jsonp";
import * as styled from "./styles";
import * as global from "components/Global";

export const SignUp = () => {
  const [email, setEmail] = useState<string>("");

  const onSubmit = (e: any) => {
    e.preventDefault();
    const url =
      "https://openflea.us10.list-manage.com/subscribe/post?u=fe75ca666e69bee14ffc18283&amp;id=b398d715fa&amp;f_id=008ed9e5f0";
    jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (_: any, data: any) => {
      console.log("data", data);
      const { msg } = data;

      alert(msg);
    });
    setEmail("");
    console.log("hi");
  };

  return (
    <form onSubmit={onSubmit}>
      <styled.SignUpWrapper>
        <h3>Buyers + Sellers</h3>
        <h2>Be the first to know.</h2>
        <p>
          Join the countdown to our big reveal! Subscribe to get early
          notifications, insider exclusives, and special perks. Sign up and be
          in the know.
        </p>
        <input
          type="email"
          placeholder="YOUR EMAIL"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <global.SecondaryButton type="submit">Sign up</global.SecondaryButton>
      </styled.SignUpWrapper>
    </form>
  );
};
