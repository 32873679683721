export const TwitterIcon = () => (
  <svg
    width="39"
    height="40"
    viewBox="0 0 39 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1788 32.8075C15.5552 32.8871 17.9234 32.4894 20.1435 31.6381C22.3636 30.7867 24.3906 29.499 26.1045 27.8509C27.8185 26.2029 29.1847 24.228 30.1224 22.043C31.0601 19.858 31.5503 17.5072 31.564 15.1295C32.653 13.7818 33.4617 12.23 33.9425 10.5653C33.9784 10.4338 33.9755 10.2947 33.9342 10.1648C33.8928 10.0349 33.8148 9.91966 33.7095 9.83305C33.6042 9.74644 33.4762 9.69212 33.3407 9.67662C33.2053 9.66112 33.0682 9.6851 32.9461 9.74568C32.3763 10.0199 31.7349 10.1085 31.1122 9.99897C30.4894 9.88941 29.9167 9.58722 29.4748 9.13498C28.9106 8.51718 28.2282 8.0189 27.4679 7.66968C26.7076 7.32045 25.885 7.12738 25.0487 7.1019C24.2125 7.07642 23.3796 7.21906 22.5995 7.52136C21.8194 7.82365 21.1079 8.27946 20.5072 8.86178C19.6846 9.65835 19.0822 10.6542 18.7583 11.7524C18.4345 12.8507 18.4003 14.0141 18.659 15.1295C13.2752 15.4509 9.57889 12.8956 6.62183 9.39212C6.533 9.29152 6.41679 9.21896 6.2874 9.18331C6.15801 9.14766 6.02104 9.15045 5.89321 9.19136C5.76539 9.23226 5.65224 9.3095 5.56759 9.41365C5.48293 9.51779 5.43044 9.64434 5.41651 9.77782C4.85317 12.9027 5.25954 16.1251 6.58104 19.0123C7.90254 21.8995 10.0759 24.3132 12.8092 25.9292C10.9765 28.031 8.40283 29.3437 5.62543 29.5933C5.47663 29.618 5.33928 29.6886 5.23258 29.7952C5.12588 29.9018 5.05519 30.0391 5.03039 30.1879C5.0056 30.3367 5.02794 30.4895 5.09429 30.6249C5.16064 30.7604 5.26769 30.8717 5.40044 30.9433C7.81674 32.1508 10.4776 32.7886 13.1788 32.8075Z"
      fill="#666666"
    />
  </svg>
);
