import styled from "@emotion/styled";
import * as consts from "consts";

export const FooterMobileWrapper = styled.div({
  display: "none",
  [consts.mobileQuery]: {
    display: "block",
  },

  ".logo-mobile": {
    width: "89px",
    height: "35px",
    margin: "0 auto",
  },
});

export const FooterWrapper = styled.div({
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  height: "116px",
  borderTop: "1px solid #888888",
  padding: "24px 123px",

  [consts.tabletQuery]: {
    height: "116px",
    padding: "24px 50px",
  },

  [consts.mobileQuery]: {
    height: "58px",
    padding: "15px 10px",

    svg: {
      height: "29px",
      width: "29px",
    },
  },
  ".logo-desktop": {
    img: {
      width: "141px",
      height: "56px",
    },

    [consts.mobileQuery]: {
      display: "none",
    },
  },

  p: {
    width: "215px",
    color: "#666",
    fontSize: "12px",
    lineHeight: "12px",

    [consts.mobileQuery]: {
      width: "100px",
      fontSize: "10px",
    },
  },

  ".socials": {
    display: "flex",
    justifyContent: "end",
    gap: "10px",
    width: "215px",

    svg: {
      cursor: "pointer",
    },
  },
});
